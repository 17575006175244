<template>
  <div class="invoice-form-container">
    <!-- 公司名称 -->
    <div class="form_item_box">
      <span class="form_item_label">公司名称</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.companyName"
      >
        <a-input style="width: 400px" placeholder="请输入公司名称" v-model="formquery.sysInvoiceInfoVo.companyName" allowClear></a-input>
      </a-form-model-item>
    </div>
    <!-- 公司税号 -->
    <div class="form_item_box">
      <span class="form_item_label">公司税号</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.companyTaxId"
      >
        <a-input style="width: 400px" placeholder="请输入公司税号" v-model="formquery.sysInvoiceInfoVo.companyTaxId" allowClear></a-input>
      </a-form-model-item>
    </div>
    <!-- 注册地址 -->
    <div class="form_item_box">
      <span class="form_item_label">注册地址</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.registerAddress"
      >
        <a-input style="width: 400px" placeholder="请输入注册地址" v-model="formquery.sysInvoiceInfoVo.registerAddress" allowClear></a-input>
      </a-form-model-item>
    </div>
    <!-- 开户银行 -->
    <div class="form_item_box">
      <span class="form_item_label">开户银行</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.bank"
      >
        <a-input style="width: 400px" placeholder="请输入开户银行" v-model="formquery.sysInvoiceInfoVo.bank" allowClear></a-input>
      </a-form-model-item>
    </div>
    <!-- 发票类型 -->
    <div class="form_item_box">
      <span class="form_item_label">发票类型</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.invoiceType"
      >
        <a-input style="width: 400px" placeholder="请输入发票类型" v-model="formquery.sysInvoiceInfoVo.invoiceType" allowClear></a-input>
      </a-form-model-item>
    </div>
    <!-- 联系电话 -->
    <div class="form_item_box">
      <span class="form_item_label">联系电话</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.contactNumber"
        :rules="[
          {
            message: '手机号无效',
            trigger: 'blur',
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          }
        ]"
      >
        <a-input style="width: 400px" placeholder="请输入联系电话" v-model="formquery.sysInvoiceInfoVo.contactNumber" allowClear></a-input>
      </a-form-model-item>
    </div>
    <!-- 发票内容 -->
    <div class="form_item_box">
      <span class="form_item_label">发票内容</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.invoiceContent"
      >
        <a-input type="textarea" :rows="4" style="width: 400px" placeholder="请输入发票内容" v-model="formquery.sysInvoiceInfoVo.invoiceContent"></a-input>
      </a-form-model-item>
    </div>
    <!-- 邮寄地址 -->
    <div class="form_item_box">
      <span class="form_item_label">邮寄地址</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.mailAddress"
      >
        <a-input style="width: 400px" placeholder="请输入邮寄地址" v-model="formquery.sysInvoiceInfoVo.mailAddress" allowClear></a-input>
      </a-form-model-item>
    </div>
    <!-- 收件人 -->
    <div class="form_item_box">
      <span class="form_item_label">收件人</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.recipient"
      >
        <a-input style="width: 400px" placeholder="请输入收件人" v-model="formquery.sysInvoiceInfoVo.recipient" allowClear></a-input>
      </a-form-model-item>
    </div>
    <!-- 收件人电话 -->
    <div class="form_item_box">
      <span class="form_item_label">收件人电话</span>
      <a-form-model-item
        prop="sysInvoiceInfoVo.recipientNumber"
        :rules="[
          {
            message: '手机号无效',
            trigger: 'blur',
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          }
        ]"
      >
        <a-input style="width: 400px" placeholder="请输入收件人电话" v-model="formquery.sysInvoiceInfoVo.recipientNumber" allowClear></a-input>
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceForm',
  created () {
    this.$set(this.formquery, 'sysInvoiceInfoVo', this.formquery.sysInvoiceInfoVo || {})
    this.$set(this.formquery, 'sysInvoiceInfoVo.companyName', this.formquery.sysInvoiceInfoVo.companyName)
    this.$set(this.formquery, 'sysInvoiceInfoVo.companyTaxId', this.formquery.sysInvoiceInfoVo.companyTaxId)
    this.$set(this.formquery, 'sysInvoiceInfoVo.registerAddress', this.formquery.sysInvoiceInfoVo.registerAddress)
    this.$set(this.formquery, 'sysInvoiceInfoVo.bank', this.formquery.sysInvoiceInfoVo.bank)
    this.$set(this.formquery, 'sysInvoiceInfoVo.invoiceType', this.formquery.sysInvoiceInfoVo.invoiceType)
    this.$set(this.formquery, 'sysInvoiceInfoVo.contactNumber', this.formquery.sysInvoiceInfoVo.contactNumber)
    this.$set(this.formquery, 'sysInvoiceInfoVo.invoiceContent', this.formquery.sysInvoiceInfoVo.invoiceContent)
    this.$set(this.formquery, 'sysInvoiceInfoVo.mailAddress', this.formquery.sysInvoiceInfoVo.mailAddress)
    this.$set(this.formquery, 'sysInvoiceInfoVo.recipient', this.formquery.sysInvoiceInfoVo.recipient)
    this.$set(this.formquery, 'sysInvoiceInfoVo.recipientNumber', this.formquery.sysInvoiceInfoVo.recipientNumber)
  },
  props: {
    value: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.form_item_box {
  display: flex;
  padding: 5px 0;
  .form_item_label {
    margin-right: 20px;
    padding-top: 10px;
    width: 100px;
  }
}
</style>
